import { environment as base } from "./environment.base";

export const environment = {
  ...base,
  code: 'futr',
  dashboard: '',
  ga: 'G-2QSYGTFPRN',
  underlyingInvestmentsChartColors: [
    { "key": "Cash", "value": "#212121" },
    { "key": "Australian Fixed Interest", "value": "#adc8ff" },
    { "key": "International Fixed Interest", "value": "#FF27C3" },
    { "key": "Alternative Defensive", "value": "#ff5722" },
    { "key": "Defensive Alternatives", "value": "#0a5aff" },
    { "key": "Australian Shares", "value": "#14c5b0" },
    { "key": "International Shares", "value": "#00d30f" },
    { "key": "Alternative Growth", "value": "#052d80" },
    { "key": "Growth Alternatives", "value": "#aa3a17" },
    { "key": "Listed Property", "value": "#0c766a" },
    { "key": "Direct Property", "value": "#2EC2E2" }
  ],
  buttonTitleTextTransform: 'SentenceCase',
  entryFileAttachedIcon: 'task',
  entryFileDefaultIcon: 'add_link',
  greenIdVerifiedIcon: 'how_to_reg',
  greenIdNotVerifiedIcon: 'new_releases',
  b2cPolicySignIn: 'B2C_1_FUTRStg_MemberPortal_SignUpSignIn',
  b2cPolicyElevatedSignIn: 'B2C_1_FUTRStg_MemberPortal_ElevatedSignIn',
  b2cSignInAuthority: 'https://apexproductdevelopment.b2clogin.com/apexproductdevelopment.onmicrosoft.com/B2C_1_FUTRStg_MemberPortal_SignUpSignIn',
  b2cElevatedSignInAuthority: 'https://apexproductdevelopment.b2clogin.com/apexproductdevelopment.onmicrosoft.com/B2C_1_FUTRStg_MemberPortal_ElevatedSignIn',
  b2cAuthorityDomain: 'apexproductdevelopment.b2clogin.com',
  b2cClientId: '773cc05d-c51c-4513-b110-aeec8ff4bd0d',
  b2cReadScope: 'https://ApexProductDevelopment.onmicrosoft.com/ad6ff878-65e4-4b91-986d-a96179b9de67/MemberPortal.User',
  b2cWriteScope: 'https://ApexProductDevelopment.onmicrosoft.com/ad6ff878-65e4-4b91-986d-a96179b9de67/MemberPortal.User',
  b2cLogoutUrl: 'https://future-super-dev.au.auth0.com/oidc/logout',
  takeMeWithYouUrl: 'tell-your-employer',
  underlyingInvestment: 'asset-allocation',
  showButtonIcon: false,
  startAPension: {
    eligibilitySection: {
      showCitizenQuestion: false
    }
  }
};


